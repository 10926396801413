import { FC } from "react";
import { ExtendedAccount } from "@practice/sdk";
import classNames from "classnames";
import { capitalize } from "lodash";

import { SVGIconProps } from "@lib/shared-types";

import ClientAvatar from "@components/Client/ClientAvatar";
import PermissionFullIcon from "@components/Icons/PermissionFullIcon";
import PermissionLimitedIcon from "@components/Icons/PermissionLimitedIcon";
import PermissionOwnerIcon from "@components/Icons/PermissionOwnerIcon";
import { Tooltip, TooltipSize } from "@components/Tooltips/Tooltip";

type RoleTypes = "admin" | "partner" | "collaborator" | "owner";

interface MemberAvatarProps {
  className?: string;
  role: RoleTypes;
  account: ExtendedAccount;
}

const roleIcons: Record<RoleTypes, FC<SVGIconProps>> = {
  admin: PermissionOwnerIcon,
  partner: PermissionFullIcon,
  collaborator: PermissionLimitedIcon,
  owner: PermissionOwnerIcon,
};

const MemberAvatar: FC<MemberAvatarProps> = ({ className, role, account }) => {
  const Icon = roleIcons[role];
  return (
    <div className={classNames("relative w-20 h-20", className)}>
      <ClientAvatar className="ml-0" size="largest" client={account ?? {}} />
      <div className="absolute top-0 right-0 w-6 h-6 rounded-full flex items-center justify-center bg-black-ink">
        <Tooltip
          size={TooltipSize.small}
          contentClassName="px-3 py-1 !text-xs"
          trigger={<Icon className="w-4 h-4 text-white" />}
        >
          {capitalize(role)}
        </Tooltip>
      </div>
    </div>
  );
};

export default MemberAvatar;

import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const TabIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 3.5C21 3.22386 20.7761 3 20.5 3H15.5C15.2239 3 15 3.22386 15 3.5V4.5C15 4.77614 15.2239 5 15.5 5H17.5858L11.2825 11.3033C11.0872 11.4985 11.0872 11.8151 11.2825 12.0104L11.9896 12.7175C12.1849 12.9127 12.5014 12.9127 12.6967 12.7175L19 6.41418V8.5C19 8.77614 19.2239 9 19.5 9H20.5C20.7761 9 21 8.77614 21 8.5V3.5ZM10.5 4C10.7761 4 11 4.22386 11 4.5V5.5C11 5.77614 10.7761 6 10.5 6H8C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H16C17.1046 18 18 17.1046 18 16V13.5C18 13.2239 18.2239 13 18.5 13H19.5C19.7761 13 20 13.2239 20 13.5V16C20 18.2091 18.2091 20 16 20H8C5.79086 20 4 18.2091 4 16V8C4 5.79086 5.79086 4 8 4H10.5Z"
      />
    </svg>
  );
};

export default TabIcon;

import { FC } from "react";
import classNames from "classnames";
import { first, last, orderBy } from "lodash";
import moment from "moment";

import { Tooltip } from "@components/Tooltips/Tooltip";

import { BillingSummaryCycleType } from "./utils";

interface BillingCycleProgressProps {
  cycles: BillingSummaryCycleType[];
  onChangeCycle: (id: string) => void;
}

const BillingCycleProgress: FC<BillingCycleProgressProps> = ({
  cycles,
  onChangeCycle,
}) => {
  const orderedCycles = orderBy(cycles, "date", "asc");
  const firstItem = first(orderedCycles);
  const lastItem = last(orderedCycles);
  const formatDate = (date: Date = new Date()) =>
    moment(date).utc().format("DD MMM YYYY");
  const firstItemDate = formatDate(firstItem?.date);
  const lastItemDate = formatDate(lastItem?.date);
  return (
    <div className="p-4">
      <div className="grid grid-flow-col gap-1 group">
        {orderedCycles?.map((item) => {
          return (
            <Tooltip
              key={`cycle-${item.id}`}
              className="inline-block ml-1"
              contentClassName="px-3 py-1 !text-xs"
              trigger={
                <div
                  className={classNames(
                    "h-1 group-hover:h-2 ease-in duration-100 rounded-lg cursor-pointer",
                    item.status === "paid" ? "bg-green-900" : "bg-green-500"
                  )}
                  onClick={() => onChangeCycle(item.id)}
                />
              }
            >
              {item.title}
            </Tooltip>
          );
        })}
      </div>
      <div className="mt-2 flex items-center justify-between text-sm text-grey-500">
        <p>{firstItemDate}</p>
        <p>{lastItemDate}</p>
      </div>
    </div>
  );
};

export default BillingCycleProgress;

import { FC } from "react";
import { ExtendedAccount } from "@practice/sdk";
import moment from "moment";

import { displayNameFromContact } from "@lib/contacts";

import InfoIcon from "@components/Icons/InfoIcon";
import { Tooltip } from "@components/Tooltips/Tooltip";

interface MemberInfoProps {
  className?: string;
  account: ExtendedAccount;
}

const MemberInfo: FC<MemberInfoProps> = ({ className, account }) => {
  const joinedAt = new Date(account.createdAt ?? "");
  return (
    <div className={className}>
      <h1 className="font-normal text-xl text-black-ink">
        {displayNameFromContact(account)}
      </h1>
      <p className="font-normal text-grey-500">{account?.email}</p>
      <p className="font-normal text-grey-500">{account?.id}</p>

      <div className="flex items-center font-normal text-grey-500 text-sm mt-2">
        <p>Joined {moment(joinedAt).fromNow()}</p>
        <Tooltip
          className="inline-block ml-1"
          contentClassName="px-3 py-1 !text-xs"
          trigger={<InfoIcon className="w-4 h-4" />}
        >
          {moment(joinedAt).format("MMMM Do YYYY")}
        </Tooltip>
      </div>
    </div>
  );
};

export default MemberInfo;

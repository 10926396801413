import { FC } from "react";

import CalendarChangeIcon from "@components/Icons/CalendarChangeIcon";
import SmallModal from "@components/Modals/SmallModal";

interface ConfirmPaidStatusModalProps {
  onAction: () => void;
  allCycles: boolean;
  actionLoading: boolean;
  toggleShow: () => void;
  show: boolean;
  markAsUnpaid: boolean;
}

const ConfirmChangePaidStatusModal: FC<ConfirmPaidStatusModalProps> = ({
  onAction,
  actionLoading,
  allCycles = false,
  markAsUnpaid = false,
  toggleShow,
  show,
}) => {
  const paidStatus = markAsUnpaid ? "unpaid" : "paid";
  const onActionText = `Confirm mark as ${paidStatus}`;
  const title = `Mark ${allCycles ? "all" : ""} as ${paidStatus}?`;
  const description = `This action will mark ${
    allCycles ? "all billing cycles" : "this billing cycle"
  } as ${paidStatus}.`;

  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      icon={CalendarChangeIcon}
      iconColor="green"
      title={title}
      description={description}
      onActionLoading={actionLoading}
      onActionText={onActionText}
      onAction={onAction}
    />
  );
};

export default ConfirmChangePaidStatusModal;

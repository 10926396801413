import { FC } from "react";
import classNames from "classnames";
import { compact } from "lodash";

import ArrowPaginationButtons from "@components/Buttons/ArrowPaginationButtons";
import Selectbox from "@components/Form/Selecbox";

import { OptionItemType } from "./utils";

const BillingCardFilterPlaceholder: FC = () => {
  const style = "bg-grey-900 dark:bg-grey-250 animate-pulse";

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center space-x-2">
        <div className={classNames("w-24 h-6 rounded-lg", style)} />
        <div className="flex-1" />
        <div className="flex space-x-0.5">
          <div className={classNames("w-8 h-8 rounded-l-lg", style)} />
          <div className={classNames("w-8 h-8 rounded-r-lg", style)} />
        </div>
        <div className={classNames("w-20 h-8 rounded-lg", style)} />
      </div>
    </div>
  );
};

interface BillingCardFilterProps {
  isLoading?: boolean;
  currentValue?: string;
  options: OptionItemType[];
  onChange: (value: string) => void;
}

const BillingCardFilter: FC<BillingCardFilterProps> = ({
  isLoading = false,
  currentValue,
  options,
  onChange,
}) => {
  const totalOptions = options?.length ?? 0;
  const hasMoreThanOneOption = totalOptions > 1;
  const isFirst = currentValue === options?.[0]?.value;
  const isLast = currentValue === options[options.length - 1]?.value;
  const isOvertime = currentValue === "overtime";

  const handleChangeNav = (action: "next" | "previous") => () => {
    const currentIndex = options.findIndex(
      (item) => item.value === currentValue
    );
    const nextIndex = action === "next" ? currentIndex + 1 : currentIndex - 1;
    onChange(options[nextIndex].value);
  };

  if (isLoading) {
    return <BillingCardFilterPlaceholder />;
  }

  if (totalOptions === 0) {
    return null;
  }

  return (
    <div className="flex items-center space-x-2">
      <h2 className="flex-1 font-medium text-black-ink">Accrued hours</h2>
      {!isOvertime && hasMoreThanOneOption && (
        <ArrowPaginationButtons
          onClickPrevious={handleChangeNav("previous")}
          onClickNext={handleChangeNav("next")}
          disablePrevious={isFirst}
          disableNext={isLast}
          tooltipPrevious="Previous cycle"
          tooltipNext="Next cycle"
        />
      )}
      <Selectbox
        options={compact([
          hasMoreThanOneOption && {
            label: "Total over time",
            value: "overtime",
          },
          ...options,
        ])}
        value={currentValue ?? ""}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default BillingCardFilter;

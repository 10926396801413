import { FC, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { kebabCase, last } from "lodash";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { useBillingRatesContext } from "@contexts/billing-rates";

import { useGetBillableRequestsContext } from "@components/BillableRequest/BillableRequestsContext";
import { TeamsSidebarReviewerBillableRequestsModal } from "@components/BillableRequest/BillableRequestsModal";

import BillingCardFilter from "./BilingCardFilter";
import BillingCardSummary from "./BillingCardSummary";
import BillingClientList from "./BillingClientList";
import EmptyBillingCardSummary from "./EmptyBillingCardSummary";
import { useSessionBillingContext } from "./session-billing-context";
import { useSessionBillingOvertimeAPI } from "./use-session-billing-cycle-summary-api";
import {
  BillingStatusType,
  BillingSummaryCycleType,
  getCyclesAsSelectOptions,
} from "./utils";

interface BillingCardMemberProps {
  className?: string;
  memberId: string;
  accountName: string;
}

const BillingCardMember: FC<BillingCardMemberProps> = ({
  className,
  memberId,
  accountName,
}) => {
  const { isOwner } = useAuth();
  const router = useRouter();
  const { setFilters, filters } = useGetBillableRequestsContext();
  const { currency = "", hasSessionBillingSettings } = useBillingRatesContext();
  const [isSummaryOpen, setIsSummaryOpen] = useState<boolean>(false);
  const [currentCycle, setCurrentCycle] = useState<string | undefined>(
    undefined
  );
  const [currentCycleDates, setCurrentCycleDates] = useState<{
    start: Date;
    end: Date;
    id?: string;
  } | null>(null);

  const {
    isCyclesFetching,
    cycles,
    getCycleSummary,
    isCycleSummaryFetching,
    cycleSummary,
  } = useSessionBillingContext();
  const { data: overtimeCycles, isLoading: isOvertimeLoading } =
    useSessionBillingOvertimeAPI(memberId);

  const totalCycles = useMemo(() => cycles?.length ?? 0, [cycles]);

  const selectedData = useMemo(() => {
    return {
      total: cycleSummary?.amount ?? 0,
      currency,
      totalSessions: cycleSummary?.totalSessions ?? 0,
      items: (cycleSummary?.sessionsGroupedByRate ?? []).map((item) => ({
        title: item.title,
        total: item.amount,
        sessions: item.quantity,
        rate: item.rate,
        isLastUpdated: item.isRateCurrentValue,
      })),
      sessionsGroupedByClient: cycleSummary?.sessionsGroupedByClient ?? [],
      status: cycleSummary?.paid ? "paid" : "unpaid",
    };
  }, [cycleSummary]);

  const overTimeFormattedData = useMemo(() => {
    if (!overtimeCycles) return null;

    const billingCycles = overtimeCycles.billingCycles ?? [];

    return {
      amount: overtimeCycles.amount,
      currency,
      sessionsGroupedByClient: overtimeCycles.sessionsGroupedByClient,
      billingCycles: billingCycles.map((item, index) => ({
        id: `cycle-${kebabCase(item.label)}`,
        title: item.label,
        total: item.amount,
        status: item.paid ? "paid" : "unpaid",
        date: item[`${index === billingCycles.length - 1 ? "end" : "start"}`],
      })) as BillingSummaryCycleType[],
    };
  }, [overtimeCycles]);

  const cycleOptions = useMemo(
    () => getCyclesAsSelectOptions(cycles),
    [cycles]
  );

  const handleChangeCycle = async (cycle: string) => {
    router.replace({ query: { ...router?.query, cycle } }, undefined, {
      shallow: true,
    });
    if (cycle === "overtime") {
      setCurrentCycle(cycle);
      return;
    }
    const selectedCycle = cycleOptions.find((item) => item.value === cycle);
    const { start = new Date(), end = new Date() } = selectedCycle ?? {};
    setCurrentCycle(cycle);
    setCurrentCycleDates({
      start,
      end,
      id: selectedCycle?.value,
    });
    await getCycleSummary(memberId, start, end);
  };

  useEffect(() => {
    if (!currentCycleDates || !currentCycle) return;
    const isOvertime = currentCycle === "overtime";
    const start = isOvertime ? last(cycles)?.start : currentCycleDates?.start;
    const end = isOvertime ? cycles[0]?.end : currentCycleDates?.end;
    setFilters({
      ...filters,
      cycleStart: start as unknown as string,
      cycleEnd: end as unknown as string,
    });
  }, [currentCycleDates, currentCycle, cycles]);

  useEffect(() => {
    if (totalCycles > 0) {
      const query = router.query;
      if (query && query.cycle === "overtime") {
        setCurrentCycle(query.cycle);
      } else {
        const { start, end, label } = cycles[0];
        getCycleSummary(memberId, start, end);
        const value = `cycle-${kebabCase(label)}`;
        setCurrentCycle(value);
        setCurrentCycleDates({ start, end, id: value });
      }
    }
  }, [memberId, totalCycles]);

  if (!hasSessionBillingSettings && isOwner) {
    return <EmptyBillingCardSummary className="m-6" />;
  }

  return hasSessionBillingSettings ? (
    <div className={classNames("grid grid-flow-row gap-4", className)}>
      <BillingCardFilter
        isLoading={isCyclesFetching}
        currentValue={currentCycle}
        options={cycleOptions}
        onChange={handleChangeCycle}
      />
      {!isCyclesFetching && !!totalCycles && (
        <BillingCardSummary
          isSummaryLoading={
            isCycleSummaryFetching || isCyclesFetching || isOvertimeLoading
          }
          isItemsLoading={isCycleSummaryFetching}
          total={selectedData.total}
          currency={selectedData.currency}
          status={selectedData.status as BillingStatusType}
          totalSessions={selectedData.totalSessions}
          items={selectedData.items}
          isOpen={isSummaryOpen}
          toggleOpen={() => setIsSummaryOpen(!isSummaryOpen)}
          cycles={
            currentCycle === "overtime" && overTimeFormattedData
              ? overTimeFormattedData.billingCycles
              : undefined
          }
          currentCycleDates={currentCycleDates}
          onChangeCycle={handleChangeCycle}
          accountName={accountName}
          memberId={memberId}
        />
      )}
      {currentCycleDates && <TeamsSidebarReviewerBillableRequestsModal />}
      {!isCyclesFetching && (
        <BillingClientList
          isFetching={isCycleSummaryFetching || isCyclesFetching}
          sessionsGroupedByClient={
            currentCycle === "overtime"
              ? overTimeFormattedData?.sessionsGroupedByClient ?? []
              : selectedData.sessionsGroupedByClient
          }
        />
      )}
    </div>
  ) : null;
};

export default BillingCardMember;

import { FC, useState } from "react";
import {
  MarkBillingTransactionsAsPaidOrUnpaidRequestActionEnum,
  SessionBillingApi,
} from "@practice/sdk";
import classNames from "classnames";
import moment from "moment";

import { useAuth } from "@contexts/auth";
import useLogger from "@hooks/use-logger";
import { useHasFullAccess } from "@hooks/use-organization-account";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import useSnackbar from "@hooks/use-snackbar";
import useToggle from "@hooks/use-toggle";
import { catchErrorSDK } from "@lib/utils/catch-error-client";

import { Button } from "@components/Button";
import CheckIcon from "@components/Icons/CheckIcon";
import ClockIcon from "@components/Icons/ClockIcon";
import CloseIcon from "@components/Icons/CloseIcon";
import LockIcon from "@components/Icons/LockIcon";
import { Tooltip } from "@components/Tooltips/Tooltip";

import BillingCardSummaryWrapper from "./BillingCardSummaryWrapper";
import BillinCycleProgress from "./BillingCycleProgress";
import ConfirmChangePaidStatusModal from "./ConfirmChangePaidStatusModal";
import CountSessionsOrCycles from "./CountSessionsOrCycles";
import {
  BillingStatusType,
  BillingSummaryCycleType,
  formatValueCurrency,
} from "./utils";

const BillingItemsPlaceholder: FC = () => {
  const style = "bg-grey-900 dark:bg-grey-250 animate-pulse";

  const renderItems = Array.from({ length: 2 }).map((_, index) => (
    <div
      key={`billing-placeholder-item-${index}`}
      className="flex items-center space-x-2"
    >
      <div className={classNames("w-6 h-6 rounded-full shrink-0", style)} />
      <div className="flex-1 flex flex-col space-y-2">
        <div className={classNames("w-14 h-4 rounded-lg", style)} />
        <div className={classNames("w-24 h-4 rounded-lg", style)} />
      </div>
      <div className={classNames("w-20 h-6 rounded-lg", style)} />
    </div>
  ));

  return <div className="flex flex-col space-y-4 px-4">{renderItems}</div>;
};

const BillingSummaryPlaceholder: FC = () => {
  const style = "rounded-lg bg-grey-900 dark:bg-grey-250 animate-pulse";
  return (
    <div className="flex items-center px-4 pt-4 pb-3">
      <div className="flex-1">
        <div className={classNames("w-24 h-6 mb-2", style)} />
        <div className="flex">
          <div
            className={classNames("w-6 h-6 !rounded-full shrink-0 mr-2", style)}
          />
          <div className={classNames("w-16 h-6", style)} />
        </div>
      </div>
      <div className={classNames("w-24 h-6", style)} />
    </div>
  );
};

type BillingSummaryItemType = {
  title: string;
  total: number;
  sessions: number;
  rate: number;
  isLastUpdated?: boolean;
};

interface BillingCardSummaryProps {
  isOpen?: boolean;
  isItemsLoading?: boolean;
  isSummaryLoading?: boolean;
  toggleOpen: () => void;
  currency: string;
  total: number;
  totalSessions: number;
  memberId: string;
  items?: BillingSummaryItemType[];
  status?: BillingStatusType;
  cycles?: BillingSummaryCycleType[];
  onChangeCycle?: (id: string) => void;
  accountName: string;
  currentCycleDates: { start: Date; end: Date; id?: string } | null;
}

const getTotalFromCycles = (cycles: BillingSummaryCycleType[]) =>
  cycles.reduce((acc, cycle) => acc + cycle.total, 0);

const BillingCardSummary: FC<BillingCardSummaryProps> = ({
  isOpen = false,
  isItemsLoading = false,
  isSummaryLoading = false,
  toggleOpen,
  currency,
  total,
  totalSessions,
  memberId,
  items,
  cycles,
  onChangeCycle,
  status,
  accountName,
  currentCycleDates,
}) => {
  const { oid } = useAuth();
  const snackbar = useSnackbar();
  const hasFullAccess = useHasFullAccess();
  const generateRequestId = useRequestIdGenerator("BillingCardSummary");
  const sessionBillingApi = useSDKApi(SessionBillingApi);
  const { logger } = useLogger("BillingCardSummary");
  const [isUpdatingPaidStatus, setIsUpdatingPaidStatus] =
    useState<boolean>(false);

  const totalCycles = cycles?.length ?? 0;
  const shouldShowCycles = !!cycles && totalCycles > 1;

  const totalFomatted = formatValueCurrency(
    shouldShowCycles ? getTotalFromCycles(cycles) : total,
    currency
  );
  const hasItems = !!items?.length;
  const shouldShowDetailsButton = !cycles && hasItems && !isSummaryLoading;
  const hasTotalSessions = totalSessions > 0;
  const hasSessionsOrCycles = hasTotalSessions || totalCycles > 0;
  const action =
    status === "paid"
      ? MarkBillingTransactionsAsPaidOrUnpaidRequestActionEnum.Unpay
      : MarkBillingTransactionsAsPaidOrUnpaidRequestActionEnum.Pay;

  const { value: paidStatusModalOpen, toggle: togglePaidStatusModal } =
    useToggle();

  const updatePaidStatus = async () => {
    if (!oid) return;
    try {
      setIsUpdatingPaidStatus(true);
      await sessionBillingApi.markBillingTransactionsAsPaidOrUnpaid({
        organizationId: oid,
        xRequestId: generateRequestId(),
        markBillingTransactionsAsPaidOrUnpaidRequest: {
          memberId,
          action,
          ...(currentCycleDates && {
            start: currentCycleDates.start,
            end: currentCycleDates.end,
          }),
        },
      });
      snackbar.showMessage("Paid status updated");
      if (onChangeCycle && currentCycleDates?.id) {
        onChangeCycle(currentCycleDates.id);
      }
    } catch (error: unknown) {
      logger.error(error, "BillingCardSummary | updatePaidStatus");
      const errorMessage = await catchErrorSDK(
        error,
        "Error updating paid status"
      );
      snackbar.showWarning(errorMessage);
    } finally {
      setIsUpdatingPaidStatus(false);
    }
  };

  const renderActionButton = () => {
    if (!hasTotalSessions || !hasFullAccess) return null;
    const isPaid = status === "paid";
    const start = moment(currentCycleDates?.start);
    const end = moment(currentCycleDates?.end);
    const now = moment();
    const isLocked = !isPaid && end.isAfter(now) && start.isBefore(now);

    const Icon = isLocked ? LockIcon : isPaid ? CloseIcon : CheckIcon;
    const variant = isPaid || isLocked ? "light-green" : "green";
    const targetStatus = isPaid ? "unpaid" : "paid";
    const info = shouldShowCycles
      ? "Once all cycles are complete, you can mark all as paid."
      : "Once the cycle is complete, you can mark it as paid.";
    const daysLeft = `(${end.diff(now, "days")} days left)`;

    const tooltipText = `${info} ${daysLeft}`;

    const button = (
      <Button
        variant={variant}
        icon={<Icon />}
        onClick={togglePaidStatusModal}
        smaller
        disabled={isLocked}
      >
        Mark {shouldShowCycles ? "all " : ""} as {targetStatus}
      </Button>
    );
    return isLocked ? (
      <Tooltip trigger={button}>{tooltipText}</Tooltip>
    ) : (
      button
    );
  };

  const sessionEmptyState = (
    <div className="text-green-200 flex flex-col gap-2">
      <ClockIcon />
      <h2 className="font-medium">No attributed sessions yet</h2>
      <p className="text-grey-500 text-sm">
        Sessions completed and attributed to {accountName} will show here.
      </p>
    </div>
  );

  const renderBasicSummary = isSummaryLoading ? (
    <BillingSummaryPlaceholder />
  ) : (
    <div className="flex items-center px-4 pt-4 pb-3">
      <div className="flex-1">
        {hasSessionsOrCycles ? (
          <p className="font-medium text-xl text-black-ink mb-1">
            {[totalFomatted, !shouldShowCycles ? "total" : ""].join(" ")}
          </p>
        ) : (
          sessionEmptyState
        )}
        {hasSessionsOrCycles && (
          <CountSessionsOrCycles
            showIcon={!shouldShowCycles}
            label={shouldShowCycles ? "cycle" : "hour"}
            total={shouldShowCycles ? totalCycles : totalSessions}
          />
        )}
      </div>
      <div>{renderActionButton()}</div>
    </div>
  );

  const renderItems = !cycles && isOpen && hasTotalSessions && (
    <div className="border-t border-grey-900 py-3">
      {isItemsLoading && <BillingItemsPlaceholder />}

      {!isItemsLoading &&
        hasItems &&
        items.map((item, index) => (
          <div
            key={`billing-summary-item-${index}`}
            className="flex items-center px-4 space-x-2 space-y-2"
          >
            <div className="w-[45px] flex">
              <div className="min-w-[36px] h-6 bg-green-600 font-medium text-center text-xs text-green-100 rounded-2xl px-2 py-1">{`${item.sessions}h`}</div>
            </div>
            <div className="flex-1 truncate">
              <p className="text-xs text-green-200 font-medium">{`${formatValueCurrency(
                item.rate,
                currency
              )} per hour`}</p>
              <p className="text-xs text-green-500 flex items-center">
                <span className="truncate">{item.title}</span>
                {item?.isLastUpdated && (
                  <span className="shrink-0 inline-block bg-blue-300 text-white font-medium text-xs px-2 py-1 rounded ml-2">
                    Rate update
                  </span>
                )}
              </p>
            </div>
            <div className="text-sm text-green-200 font-medium">
              {formatValueCurrency(item.total, currency, 2)}
            </div>
          </div>
        ))}
    </div>
  );

  const renderCycles = () => {
    if (
      isSummaryLoading ||
      isItemsLoading ||
      !shouldShowCycles ||
      !onChangeCycle
    ) {
      return null;
    }

    return (
      <BillinCycleProgress cycles={cycles} onChangeCycle={onChangeCycle} />
    );
  };

  return (
    <>
      <BillingCardSummaryWrapper className="overflow-hidden">
        {renderBasicSummary}
        {renderItems}
        {renderCycles()}
        {shouldShowDetailsButton && (
          <button
            className="text-sm text-grey-500 border-t border-grey-900 p-3"
            onClick={toggleOpen}
          >
            {`${isOpen ? "Hide" : "Show"} details`}
          </button>
        )}
      </BillingCardSummaryWrapper>
      {paidStatusModalOpen && (
        <ConfirmChangePaidStatusModal
          show
          toggleShow={togglePaidStatusModal}
          allCycles={shouldShowCycles}
          markAsUnpaid={status === "paid"}
          onAction={updatePaidStatus}
          actionLoading={isUpdatingPaidStatus}
        />
      )}
    </>
  );
};

export default BillingCardSummary;

import React, { FC } from "react";

import { SVGIconProps } from "@lib/shared-types";

const PendingIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4851_30082)">
        <circle cx="8" cy="8" r="8" fill="#8A898E" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99992 11.3334C9.84087 11.3334 11.3333 9.84099 11.3333 8.00004C11.3333 6.15909 9.84087 4.66671 7.99992 4.66671C6.15897 4.66671 4.66659 6.15909 4.66659 8.00004C4.66659 9.84099 6.15897 11.3334 7.99992 11.3334ZM7.99992 12.6667C10.5772 12.6667 12.6666 10.5774 12.6666 8.00004C12.6666 5.42271 10.5772 3.33337 7.99992 3.33337C5.42259 3.33337 3.33325 5.42271 3.33325 8.00004C3.33325 10.5774 5.42259 12.6667 7.99992 12.6667Z"
          fill="white"
        />
        <rect
          x="7.33325"
          y="8.66663"
          width="2.66667"
          height="1.33333"
          transform="rotate(-90 7.33325 8.66663)"
          fill="white"
        />
        <rect
          x="7.33325"
          y="8.66663"
          width="1.33333"
          height="2.66667"
          transform="rotate(-90 7.33325 8.66663)"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4851_30082">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PendingIcon;

import { FC } from "react";
import { compact } from "lodash";
import pluralize from "pluralize";

import FlagIcon from "@components/Icons/Flag3Icon";

interface CountSessionsOrCyclesProps {
  showIcon?: boolean;
  label: "hour" | "cycle";
  total: number;
}

const CountSessionsOrCycles: FC<CountSessionsOrCyclesProps> = ({
  showIcon = true,
  label,
  total,
}) => (
  <div className="flex items-center space-x-1">
    {showIcon && (
      <span className="inline-block bg-green-600 rounded-full p-1">
        <FlagIcon className="w-4 h-4 text-green-200" />
      </span>
    )}
    <p className="text-sm font-medium text-green-200">
      {compact([
        total,
        label === "cycle" && "Billing",
        pluralize(label, total),
      ]).join(" ")}
    </p>
  </div>
);

export default CountSessionsOrCycles;

import { FC, ReactNode, useState } from "react";
import classNames from "classnames";
import Image from "next/image";

import { Button } from "@components/Button";
import ChatIcon from "@components/Icons/ChatIcon";
import SocialWebIcon from "@components/Icons/SocialWebIcon";
import TeamsBadgeIcon from "@components/Icons/TeamsBadgeIcon";
import SubscriptionPlanModal from "@components/Plans/SubscriptionPlanModal";

interface CardProps {
  title: string;
  text: string;
  icon?: ReactNode;
  illustration?: ReactNode;
  className?: string;
}

const Card: FC<CardProps> = ({
  title,
  text,
  icon,
  illustration,
  className,
}) => (
  <div
    className={classNames(
      "flex-1 flex flex-col items-start gap-2 bg-white rounded-2xl",
      !illustration && "p-4",
      className
    )}
  >
    {icon}
    <div
      className={classNames(
        "flex flex-col gap-1",
        illustration && "p-4 mb-auto"
      )}
    >
      <h3 className="font-medium">{title}</h3>
      <p className="text-sm text-gray-500">{text}</p>
    </div>
    {illustration}
  </div>
);

interface CardsProps {
  className?: string;
}

const Cards: FC<CardsProps> = ({ className }) => (
  <div className={classNames("flex flex-col gap-4", className)}>
    <div className="flex flex-col sm:flex-row gap-4">
      <Card
        title="Team dashboard"
        text="Quickly view the performance of your entire team in a simple view."
        illustration={
          <Image
            className="self-center"
            src="/images/ill_team_dashboard@3x.png"
            width={344}
            height={224}
            alt="Team dashboard illustration"
          />
        }
      />
      <Card
        title="Activity updates"
        text="Easily view the contribution and activity of each member on your team."
        illustration={
          <Image
            className="self-center"
            src="/images/ill_activity_updates@3x.png"
            width={344}
            height={224}
            alt="Activity updates illustration"
          />
        }
      />
    </div>
    <div className="flex-1 flex flex-col sm:flex-row gap-4">
      <Card
        icon={
          <Image src="/icons/ill_unlock.svg" width={24} height={24} alt="" />
        }
        title="Unlimited members"
        text="Manage staff and contract workers under one roof."
      />
      <Card
        icon={<ChatIcon />}
        title="Internal chat"
        text="Stay on top of your clients and easily communicate with your team."
      />
      <Card
        icon={<SocialWebIcon />}
        title="Company/Team profile"
        text="Members of your team have their own profile under your company umbrella."
      />
    </div>
  </div>
);

interface TeamsMarketingSectionProps {
  className?: string;
}

const TeamsMarketingSection: FC<TeamsMarketingSectionProps> = ({
  className,
}) => {
  const [showChangePlanModal, setShowChangePlanModal] =
    useState<boolean>(false);

  return (
    <>
      <div
        className={classNames(
          "flex flex-col gap-8 p-8 bg-gradient-to-br from-grey-950 to-action-500/40 rounded-2xl",
          className
        )}
      >
        <div className="flex gap-1 items-center">
          <span className="text-sm text-gray-500">Introducing</span>
          <TeamsBadgeIcon className="h-[18px] w-auto" />
        </div>
        <div className="sm:w-1/2 flex flex-col gap-4 items-start">
          <div className="flex flex-col gap-1">
            <h2 className="text-2xl font-medium">
              Manage your team on Practice
            </h2>
            <p className="text-sm text-gray-500">
              As your business grows so does your team. With Practice you can
              manage clients across your entire team in one place.
            </p>
          </div>
          <Button
            variant="primary"
            small
            onClick={() => setShowChangePlanModal(true)}
          >
            Upgrade to Teams
          </Button>
        </div>
        <Cards className="hidden sm:flex" />
      </div>

      <SubscriptionPlanModal
        show={showChangePlanModal}
        toggleShow={setShowChangePlanModal}
        onClose={() => setShowChangePlanModal(false)}
      />
    </>
  );
};

export default TeamsMarketingSection;

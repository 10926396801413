import { FC } from "react";
import compact from "lodash/compact";

import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";

import SmallBanner from "@components/Banner/SmallBanner";
import ClientAvatar from "@components/Client/ClientAvatar";
import AlarmOffIcon from "@components/Icons/AlarmOffIcon";
import CancelIcon from "@components/Icons/BlockIcon";
import CreditCardIcon from "@components/Icons/CreditCardIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import LoopIcon from "@components/Icons/LoopIcon";
import SmallModal from "@components/Modals/SmallModal";

export interface RemoveMemberModalProps {
  account: AccountType;
  toggleShow: () => void;
  handleRemoveMember: (accountId: AccountType) => void;
  removeMemberLoading: boolean;
  isPaying?: boolean;
}

const RemoveMemberModal: FC<RemoveMemberModalProps> = ({
  account,
  toggleShow,
  handleRemoveMember,
  removeMemberLoading,
  isPaying,
}) => {
  const firstName = displayNameFromContact(account, true);
  const title = <div className="truncate">Remove {firstName}</div>;
  const description = `${firstName}'s account and access to your Practice team will be immediately removed.`;

  const infoList = compact([
    {
      icon: AlarmOffIcon,
      detail: `${firstName} will not be notified of their removal`,
    },
    {
      icon: LoopIcon,
      detail: `At anytime, you can re-invite ${firstName}`,
    },
    isPaying && {
      icon: CreditCardIcon,
      detail: "You will no longer be billed for this user",
    },
  ]);

  const renderDetail = (
    { icon: Icon, detail }: (typeof infoList)[0],
    index: number
  ) => (
    <div
      key={`remove-info-${index}`}
      className="flex items-center gap-2 text-grey-500 text-sm"
    >
      <Icon className="flex-none" />
      <p>{detail}</p>
    </div>
  );

  const headerIcon = () => (
    <div className="relative">
      <ClientAvatar client={account} size="large" />
      <div className="bg-peach-800 p-1 rounded-full absolute top-0 right-0">
        <CancelIcon className="text-peach-400 w-4 h-4" />
      </div>
    </div>
  );

  const renderSubscriptionInfo = (
    <SmallBanner
      className="text-grey-500 mt-2"
      items={[
        {
          Icon: InfoIcon,
          text: "Your billed member days won't be reimbursed or credited back.",
        },
      ]}
      variant="regular"
    />
  );

  return (
    <SmallModal
      show
      toggleShow={toggleShow}
      heapModalName="remove_team_member_modal"
      title={title}
      description={description}
      onActionText="Confirm removal"
      onActionLoading={removeMemberLoading}
      onAction={() => handleRemoveMember(account)}
      iconColor="peach"
      bigIcon
      icon={headerIcon}
    >
      <div className="flex flex-col gap-3">
        {infoList.map(renderDetail)}
        {renderSubscriptionInfo}
      </div>
    </SmallModal>
  );
};

export default RemoveMemberModal;

import React, { FC } from "react";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="192"
      height="192"
      fill="none"
      viewBox="0 0 192 192"
    >
      <defs>
        <linearGradient id="paint0_linear" x1="0.5" x2="0.5" y1="0.002" y2="1">
          <stop offset="0.167" stopColor="#548FF7"></stop>
          <stop offset="1" stopColor="#3D74F1"></stop>
        </linearGradient>
      </defs>
      <g>
        <path fill="#fff" d="M25 25H155V155H25z"></path>
        <path
          fill="url(#paint0_linear)"
          fillRule="evenodd"
          d="M13.396 45.025C9 55.638 9 69.155 9 96.188c0 27.033 0 40.549 4.396 51.162 5.861 14.15 17.479 25.393 31.629 31.254C55.638 183 69.092 183 96 183s40.362 0 50.975-4.396c14.15-5.861 25.768-17.291 31.629-31.442C183 136.55 183 123.033 183 96c0-27.033 0-40.55-4.396-51.163-5.861-14.15-17.479-25.58-31.629-31.441C136.362 9 122.908 9 96 9s-40.362 0-50.975 4.396c-14.15 5.861-25.768 17.479-31.629 31.629zM43 72.5a6 6 0 016-6h51.75c10.355 0 18.75 8.395 18.75 18.75v34.875a6 6 0 01-6 6H61.75c-10.355 0-18.75-8.395-18.75-18.75V72.5zm81 16.073c0-3.641 1.654-7.086 4.495-9.363l13.691-10.973c2.946-2.36 7.314-.264 7.314 3.512v49.074c0 3.784-4.387 5.878-7.33 3.498l-13.716-11.094a11.999 11.999 0 01-4.454-9.33V88.573z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default Icon;

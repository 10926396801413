import { FC, useState } from "react";
import { ClientSessions } from "@practice/sdk";
import classNames from "classnames";
import { isEmpty } from "lodash";

import pluralHelper from "@lib/utils/pluralHelper";

import BillingClientItem from "./BillingClientItem";

const BillingClientListPlaceholder: FC = () => {
  const style = "bg-grey-900 dark:bg-grey-250 animate-pulse";

  const renderItems = Array.from({ length: 3 }).map((_, index) => (
    <div
      key={`billing-placeholder-item-${index}`}
      className="flex items-center space-x-2"
    >
      <div className={classNames("w-6 h-6 rounded-full shrink-0", style)} />
      <div className="flex-1 flex flex-col space-y-2">
        <div className={classNames("w-24 h-4 rounded-lg", style)} />
      </div>
      <div className={classNames("w-20 h-4 rounded-lg", style)} />
      <div className={classNames("w-9 h-6 rounded-full", style)} />
    </div>
  ));

  return (
    <div className="flex flex-col space-y-4 p-4 border rounded-lg">
      {renderItems}
    </div>
  );
};

interface BillingClientListProps {
  sessionsGroupedByClient: ClientSessions[];
  isFetching: boolean;
}

const BillingClientList: FC<BillingClientListProps> = ({
  isFetching,
  sessionsGroupedByClient,
}) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<ClientSessions | null>(
    null
  );

  const filteredClientSessions = sessionsGroupedByClient.filter(
    (session) => !isEmpty(session.packages)
  );

  const totalClients = filteredClientSessions.length;

  if (totalClients === 0) {
    return null;
  }

  const toggleOpenAll = () => {
    setShowAll(!showAll);
    setSelectedClient(null);
  };

  const toggleClient = (client: ClientSessions) => {
    if (selectedClient?.client?.id === client.client.id) {
      setSelectedClient(null);
    } else {
      setSelectedClient(client);
    }
  };

  if (isFetching) {
    return <BillingClientListPlaceholder />;
  }

  const renderControl = (
    <div className="flex items-center justify-between">
      <h3 className="font-medium text-black-ink">
        {pluralHelper(totalClients, "client")}
      </h3>
      <p
        className="text-sm text-grey-500 hover:text-grey-300 cursor-pointer"
        onClick={toggleOpenAll}
      >{`${showAll ? "Close" : "Open"} all`}</p>
    </div>
  );

  const renderClientList = () => {
    return (
      <div className="flex flex-col overflow-hidden rounded-lg border border-grey-900 divide-y divide-grey-900 cursor-pointer">
        {filteredClientSessions.map((client) => (
          <BillingClientItem
            key={client.client.id}
            clientSessions={client}
            toggleClient={toggleClient}
            isOpen={selectedClient?.client?.id === client.client.id || showAll}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="grid gap-1">
      {renderControl}
      {renderClientList()}
    </div>
  );
};

export default BillingClientList;

import classNames from "classnames";

export const IntegrationInformation: React.FC<{
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  hasIntegration: boolean;
}> = ({ Icon, hasIntegration }) => {
  const iconCasses = classNames("h-6 w-6", !hasIntegration && "grayscale");

  return (
    <div className="flex items-center space">
      <Icon className={iconCasses} />
      <div
        className={classNames(
          "text-3xl ml-1",
          hasIntegration ? "text-green-600" : "text-peach-500"
        )}
      >
        •
      </div>
    </div>
  );
};

export default IntegrationInformation;
